export const isValidEmail = (email) => {
  return (
    !!email &&
    String(email).length > 0 &&
    String(email).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
  );
};

export const validateEmail = (email) => {
  // check comma separated emails validity
  const emailList = (email || "").split(",").map((e) => e.trim());
  return {
    isValid: emailList.every(isValidEmail),
    value: emailList.length > 1 ? emailList.join(",") : emailList[0],
  };
};

export const isValidAddress = (address) =>
  String(address).trim().length > 1 && String(address).trim().length <= 100;

export const isValidCity = (city) =>
  String(city)
    .replace(/[^A-Za-z\s-_,]/g, "")
    .trim().length > 1;

export const isValidZip = (zip) => /^([A-Z\d\s]){2,}$/.test(String(zip).trim());

export const isEmpty = (val) => !val || String(val).length === 0;

export const isEmptyObject = (obj) => {
  if (!obj) return true;
  if (typeof obj !== "object") return false;

  for (const key in obj) {
    if (!obj[key]) return true;

    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      if (isEmptyObject(obj[key])) {
        return true;
      }
    } else if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; ++i) {
        if (!isEmptyObject(obj[key][i])) return false;
      }
    }
  }

  return false;
};
